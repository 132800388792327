import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';

const Price = ({ price }) => (
  <div className="product-price">
    {accounting.formatMoney(price, '€', 2, ' ', ',', '%v %s')}
  </div>
);

Price.propTypes = {
  price: PropTypes.number.isRequired
};

export default Price;