import React from 'react';

import {
  Link
} from 'react-router-dom';

import { useSpring, config, animated } from 'react-spring';

const AnimatedBlockArticleThumb = animated(Link);

const BlockArticleThumb = ({
  id,
  title,
  thumbnailUrl
}) => {
  const props = useSpring({ 
    from: { opacity: 0 }, 
    to: { opacity: 1 }, 
    config: config.default,
    delay: 100
  });

  return (
    <AnimatedBlockArticleThumb 
      style={{ backgroundImage: `url(${thumbnailUrl})`, ...props }}
      to={`/blog/${id}`}
      className="blog-article-thumb"
    >
      <div>
        {title}
      </div>
    </AnimatedBlockArticleThumb>
  );
};

export default BlockArticleThumb;