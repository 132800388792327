import './Housings.less';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  HousingsPage,
  HousingPage
} from '.';

const Housings = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} exact component={HousingsPage}/>
    <Route
      path={`${match.url}/:slug`} render={({ match }) => {
        return (<HousingPage slug={match.params.slug}/>);
      }}/>
  </Switch>
);

export default Housings;