import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import CartItemFragment from './CartItemFragment';

const CartItems = ({ items, isMobile, readOnly, stockDeltas, style }) => {
  
  const deltas = _.keyBy(stockDeltas, 'id');
  if (_.size(items) === 0) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        {'Il n\'y a pas de produit dans votre panier'}
      </div>
    );
  }
  return items.map((item) => {
    const delta = _.isUndefined(deltas[item.__id]) ? 0 : deltas[item.__id].value;
    return <CartItemFragment key={Math.random(1)} item={item} readOnly={readOnly} delta={delta}/>;
  });
};

CartItems.defaultProps = {
  readOnly: false,
  stockDeltas: []
};

CartItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      qty: PropTypes.number,
      price: PropTypes.number,
      option: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string
      })
    })
  ).isRequired,
  readOnly: PropTypes.bool,
  stockDeltas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number
    })
  )
};

export default CartItems;