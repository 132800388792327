import _ from 'lodash';
import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import qs from 'query-string';
import Modal from './containers/modal/Modal';
import Cgv from './containers/misc/Cgv';

const ModalRouter = ({ history, location }) => {
  const query = qs.parse(location.search);

  if (!_.has(query, 'modal')) return null;
  let content = null;
  if (query.modal === 'cgv') {
    content = <Cgv/>;
  } else {
    return <Redirect to="/"/>;
  }

  return (
    <Modal>
      {content}
    </Modal>
  );
};

export default withRouter(ModalRouter);

