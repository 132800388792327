import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import accounting from 'accounting';

export const Event = (() => {
  
  const transaction = ({ orderRef, total }) => {
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      'id': orderRef,
      'affiliation': 'les petites emplettes',
      'revenue': accounting.formatMoney(total.totalWithTax, '', 2, ' ', '.'),
      'shipping': accounting.formatMoney(total.shippingWithTax, '', 2, ' ', '.'),
      'tax': accounting.formatMoney(total.tax, '', 2, ' ', '.'),
    });
    ReactGA.plugin.execute('ecommerce', 'send');
  };

  const addImpression = ({ productId, name, optionName, category = '', list, position }) => {

    ReactGA.plugin.execute('ec', 'addImpression', {
      'id': productId,
      name,
      category,
      brand: 'les petites emplettes',
      variant: optionName,
      list,
      position
    });
  };  
  return {
    addImpression,
    transaction
  };
})();
  
const GoogleAnalytics = ({
  history,
  children
}) => {

  const logRoute = () => {

    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
  };
  
  useEffect(() => {
    ReactGA.initialize('UA-38748650-1', {
      debug: true,
      siteSpeedSampleRate: 100
    });
    ReactGA.plugin.require('ecommerce');
    ReactGA.plugin.require('ec');
    

    logRoute();
    
    history.listen(() => {
      logRoute();
    });

  }, []);

  return (
    <div>
      {children}
    </div>
  );
};

export default withRouter(GoogleAnalytics);


