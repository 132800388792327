import React, { Component } from 'react';
import { Motion, spring, presets } from 'react-motion';
import styled from 'styled-components';
import cookie from 'js-cookie';
import { Button } from '../uikit/UIKit';

import {
  branch,
  compose,
  renderNothing,
  withState,
  withHandlers
} from 'recompose';

const S_AcceptCookies = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  background-color: #444;  
  .accept-cookies-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;    
  }
  .accept-cookies-main {
    display: flex;
    overflow: hidden;
    align-items: center;
    text-transform: uppercase;
    color: white;
    font-size: 11px;
    padding-left: 8px;
    .buttons {
      margin-left: 16px;
    }
  }
`;

const enhance = compose(
  withState(
    'height',
    'setHeight',
    80
  ),
  withHandlers({
    onClick: ({ setHeight }) => () => {
      cookie.set('accept_cookies', 'true');
      setHeight(0);
    }
  })
);

const AcceptCookies = enhance(
  ({
    height,
    onClick
  }) => (
    <S_AcceptCookies>
      <Motion defaultStyle={{ height: 80 }} style={{ height: spring(height, presets.stiff) }}>
        {({ height }) => (
          <div className="accept-cookies-wrapper" style={{ height: height + 'px' }}>
            <div className="accept-cookies-main">
              Ce site utilise des cookies pour assurer son fonctionnement
              <div className="buttons">
                <Button onClick={onClick}>Ok</Button>
              </div>
            </div>
          </div>
        )}
      </Motion>
    </S_AcceptCookies>
  )
);

export default branch(
  () => cookie.get('accept_cookies') === 'true',
  renderNothing
)(AcceptCookies);
