import React from 'react';
import classNames from 'classnames';

const Stock = ({ stock }) => (
  <div className="product-stock">
    <div className={classNames('stock-info', { 'stock-out-of-stock': stock === 0 })}>
      {stock === 0 ? 'Ce produit n\'est plus en stock' : 'Ce produit est disponible'}
    </div>
  </div>
);

export default Stock;