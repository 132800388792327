import React from 'react';
import PropTypes from 'prop-types';
import Button from '../uikit/Button';

const AddToCart = ({ disabled, addToCart, addingToCart, style }) => (
  <div {...{ style }}>
    <Button
      fetching={addingToCart}
      size="big"
      disabled={disabled}
      onClick={() => addToCart()}
    >
      {'Ajouter au panier'}
    </Button>
  </div>
);

AddToCart.propTypes = {
  disabled: PropTypes.bool.isRequired,
  addToCart: PropTypes.func.isRequired,
  addingToCart: PropTypes.bool
};

export default AddToCart;