import React from 'react';
import { Back } from '../uikit/UIKit';

const CategoryNotFound = () => (
  <div className="block">
    <div className="block-body block-body-center">{'Cette catégorie n\'existe pas'}</div>
    <Back/>
  </div>
);

export default CategoryNotFound;