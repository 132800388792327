import _ from 'lodash';
import React from 'react';

import SignIn from './account/SignIn';
import SignUp from './account/SignUp';
import Info from './account/Info';
import Title from './components/Title';

const OrderAccount = ({ user }) => (
  <div className="block">
    <div className="block-top">
      <Title icon="user" value="Mon compte"/>
    </div>
    <div className="block-body">
      {_.isEmpty(user.email) && <SignIn/>}
      {
        _.isEmpty(user.email) && 
        <div style={{ marginTop: '32px' }}>
          <SignUp/>
        </div>
      }
      {!_.isEmpty(user.email) && <Info {...{ user }}/>}
    </div>
  </div>
);

export default OrderAccount;