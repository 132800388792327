import './Template.less';
import React from 'react';

import { Link } from 'react-router-dom';

import Footer from '../footer/Footer';

const Template = ({ children }) => (
  <div className="template">
    <div className="template-blog template-blog-top">
      <Link to="/">{'la boutique'}</Link>
      &nbsp;&nbsp;&nbsp;
      <Link to="/housings">{'hébergements'}</Link>
    </div>
    <div className="template-blog">
      <h1>les petites emplettes</h1>
      <h2>blog</h2>
      <div className="template-blog-content">
        {children}
      </div>
    </div>
    <Footer className="footer"/>
    <div className="footer-image">
      <img src="/images/craspedia.png" width="200px"/>
    </div>
  </div>
);

export default Template;