import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';

import Button from '../uikit/Button';

const OrderSuccess = ({ orderRef, history, location }) => {
  const { ref } = qs.parse(location.search);
  return (
    <div className="block">
      <h2 className="block-title">Commande</h2>
      <div className="block-body block-body-center">
        <div style={{ margin: '8px 0px' }}>
          <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: '#6e9f2f' }} />
        </div>
        <div style={{ margin: '8px 0px' }}>
          {'Merci beaucoup !'}
        </div>
        <div style={{ marginTop: '16px' }}>
          <Button
            onClick={() => history.replace(`/myaccount/orders/${ref}`)}
          >
            {'Voir ma commande'}
          </Button>
        </div>
      </div>
    </div>
  );
};

OrderSuccess.propTypes = {
  orderRef: PropTypes.string
};

export default withRouter(OrderSuccess);
