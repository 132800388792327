import React from 'react';

import {
  graphql,
  createFragmentContainer
} from 'react-relay';

import CartTotal from '../cart/CartTotal';

const OrderTotal = ({ total, isMobile }) => {
  return React.createElement(
    CartTotal,
    {
      total
    }
  );
};

OrderTotal.defaultProps = {
  isMobile: true
};

export default createFragmentContainer(
  OrderTotal,
  {
    total: graphql`
      fragment OrderTotal_total on OrderTotal {
        country
        productsWithTax
        productsNoTax
        shippingNoTax
        shippingWithTax
        extraShippingWithTax,
        extraShippingNoTax
        tax
        totalWithTax
        totalNoTax
        mustCheckShippingCost
        totalWithTaxWithoutCoupon
      }
    `
  }
);