import './Loading.less';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';

const Loading = () => (
  <div className="loading">
    <FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
  </div>
);

export default Loading;