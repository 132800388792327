import React from 'react';

import {
  Link
} from 'react-router-dom';

import { useSpring, config, animated } from 'react-spring';

const AnimatedHousingThumb = animated(Link);

const HousingThumb = ({ value }) => {
  const props = useSpring({ 
    from: { opacity: 0 }, 
    to: { opacity: 1 }, 
    config: config.default,
    delay: 100
  });

  return (
    <AnimatedHousingThumb 
      to={`/housings/${value.slug}`}
      className="housing-thumb"
      style={{ backgroundImage: `url(${value.picture1.url})`, ...props }}
    >
      <div>
        {value.title}
      </div>
    </AnimatedHousingThumb>
  );
};

export default HousingThumb;