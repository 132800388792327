const R = require('ramda');
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ItemContainer = styled.a`
  position: relative;
  //min-width: 200px;
  padding: 4px;
  width: 100%;
  max-width: ${props => props.width}px;
  
  &:first-of-type {
    padding-left: 0px;
  }
  
  &:last-of-type {
    padding-right: 0px;
  }

  img {
    width: 100%;
  }   
  .img-title {
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 11px;
    letter-spacing: 3.0;
    text-transform: uppercase;
    color: #555;
  }
  .img-text {
    width: 100%;
  }

  
`;

const ItemText = styled.div`
  width: 100%;
  @media screen and (max-width: 700px) {
    padding: 0px 4px; 
  }
`;

const Item = ({ src, to, title, text, size }) => (
  <ItemContainer href={to} width={size}>
    <img className="lpe-image" src={src}/>
    { 
      !R.isEmpty(title) &&
      <div className="img-title">
        {title}
      </div>
    }
    {
      !R.isEmpty(text) &&
      <ItemText>
        {text}
      </ItemText>
    }
  </ItemContainer>
);

Item.defaultProps = {
  title: '',
  size: 400
};

Item.propTypes = {
  size: PropTypes.number
};
export default Item;