import { withStateHandlers } from 'recompose';

import React from 'react';
import WayPoint from 'react-waypoint';
import Transition from 'react-transition-group/Transition'; 

const duration = 250;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 }
};

const OnAppear = withStateHandlers(
  () => ({
    show: false
  }),
  {
    onEnter: () => () => ({ show: true })
  }
)(
  ({ show, onEnter, children }) => 
    <WayPoint 
      onEnter={() => onEnter()}
    >
      <div style={{ width: '100%' }}>
        <Transition
          in={show}
          timeout={duration}
        >
          {(state) => (
            <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
              {children}
            </div>
          )}
        </Transition>
      </div>
    </WayPoint>
);

export default OnAppear;