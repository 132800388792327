import React from 'react';
import { Back } from '../uikit/UIKit';

const ProductNotFound = () => (
  <div className="block">
    <div className="block-body block-body-center">{'Ce produit n\'existe pas'}</div>
    <Back/>
  </div>
);

export default ProductNotFound;