import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';


class Qty extends Component {

  onSelectQty (qty) {
    this.props.updateQty(Number(qty));
  }

  render () {
    const { stock, qty } = this.props;

    if (stock === 0) return null;
    
    return (
      <div className="product-qty">
        <Select 
          disabled={stock === 0}
          clearable={false}
          searchable={false}          
          style={{ width: '150px', textAlign: 'center' }}
          options={Qty.options} 
          simpleValue={true} value={qty} 
          onChange={v => this.onSelectQty(v)}
        />
      </div>
    );    
  }
}

Qty.options = [
  { value: 0, label: 'qté : 0' }, 
  { value: 1, label: 'qté : 1' },
  { value: 2, label: 'qté : 2' },
  { value: 3, label: 'qté : 3' },
  { value: 4, label: 'qté : 4' },
  { value: 5, label: 'qté : 5' }
];

Qty.propTypes = {
  stock: PropTypes.number.isRequired,
  qty: PropTypes.number.isRequired,
  updateQty: PropTypes.func.isRequired
};

export default Qty;