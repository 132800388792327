/* eslint-disable space-before-function-paren */
import './Modal.less';
import { withRouter } from 'react-router-dom';

import React, { Component } from 'react';
import Button from '../uikit/Button';

class Modal extends Component {

  onClose() {
    const { history } = this.props;
    history.push(location.pathName);
  }

  componentDidMount() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        this.onClose();
      }
    };
  }
  render() {
    return (
      <div className="modal-box">
        <div className="modal-box-content">
          <div>
            {this.props.children}
          </div>
          <div className="modal-box-content-close">
            <Button
              small={true}
              onClick={() => this.onClose()}
            >
              Fermer
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Modal);