import _ from 'lodash';
const R = require('ramda');
import React from 'react';
import styled from 'styled-components';
import OnAppear from '../OnAppear';
import Item from './Item';

const HomeRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 32px auto;
  width: 100%;
  max-width: 800px;
`;

const prepareRows = (items, isMobile) => {
  let rows = [];
  let tempItems = items;
  let rowSize = R.length(items);
  if (isMobile) {
    rowSize = Math.min(2, rowSize);
  }

  do {
    const itemsInRow = _.take(tempItems, rowSize);
    rows.push(itemsInRow);
    tempItems = _.slice(items, _.size(itemsInRow), _.size(tempItems));
  } while (_.size(tempItems) > 0);

  return rows;
};

const RowOfItems = ({
  isMobile,
  items
}) => {
  return (
    <OnAppear>
      <div>
        {prepareRows(items, isMobile).map((row, index) => (
          <HomeRow key={index}>
            {
              row.map((item, index) => (
                <Item
                  key={index}
                  src={item.image.url}
                  title={item.title}
                  text={item.description}
                  to={item.link}
                  size={item.size}
                />
              ))
            }
          </HomeRow>
        ))}
      </div>
    </OnAppear>
  );
};

export default RowOfItems;