import React from 'react';
import styled from 'styled-components';

const S_Info = styled.div`
`;

const Info = ({ user }) => (
  <S_Info>
    {`Vous êtes connecté(e) avec l'adresse email : ${user.email}`}
  </S_Info>
);

export default Info;