import React from 'react';
import styled from 'styled-components';

const S_Title = styled.h2`
  display: flex;
  align-items: center;
  div:first-of-type {
    display: none;
    //display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    font-size: 18px;
    color: #bbb;
    background-color: white;
    //border: 2px solid #C00420;
    //background-color: #C00420;
    //color: white;
  }
`;

const Title = ({ icon, value }) => (
  <S_Title>
    {value}
  </S_Title>
);

export default Title;