import React, { Component } from 'react';

export const withIsMobile = (WrappedComponent) => {
  // eslint-disable-next-line react/display-name
  return class extends Component {

    constructor (props) {
      super(props);

      this.state = {
        isMobile: window.innerWidth <= 700 
      };
    }

    componentDidMount () {
      window.addEventListener('resize', () => {
        this.setState({ isMobile: window.innerWidth <= 700 });
      });
    }
  
    render () {
      const { isMobile } = this.state;
      return <WrappedComponent isMobile ={isMobile} {...this.props}/>; 
    }
  };
};