import React from 'react';
import styled from 'styled-components';

const S_Textarea = styled.textarea`
  border-color: #eee;
  border-radius: 4px;
  &::placeholder {
    color: #ccc;
  }
`;

const OrderMessage = ({ message, onUpdate }) => (
  <div className="block">
    <div className="block-top">
      <h2>Laisser un message</h2>
    </div>
    <div className="block-body">
      <S_Textarea
        placeholder="Votre message"
        value={message} 
        className="block-textarea" 
        rows={5} 
        onChange={e => onUpdate(e.target.value)}/>
    </div>
  </div>
);

export default OrderMessage;