import './Back.less';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/pro-light-svg-icons';

const Back = ({ history }) => (
  <div className="back">
    <a href="javascript:void(0);" onClick={() => history.goBack()}>
      <FontAwesomeIcon icon={faArrowAltCircleLeft} size="3x" />
    </a>
  </div>
);

export default withRouter(Back);