import React from 'react';

const Cgv = () => (
  <div className="block">
    <div className="block-top">
      <h1>{'Conditions générales de ventes'}</h1>
    </div>
    <h2>{'Préambule'}</h2>
    <div className="block-body">
      {'La société UNE USINE vend des produits sous la marque LES PETITES EMPLETTES.'}
      <br/><br/>
      {'UNE USINE se réserve le droit d\'adapter ou de modifier les présentes Conditions Générales de Vente.'}
      <br/><br/>
      {'En cas de modification, seront appliquées à chaque commande les conditions en vigueur au jour de la commande.'}
      <br/><br/>
      {'Ces conditions concernent exclusivement les CLIENTS non commerçants.'}
    </div>
    <h2>{'La commande'}</h2> 
    <div className="block-body">
      {'Toute commande ne sera prise en considération qu\'après acceptation du paiement.'}
      <br/><br/>
      {'UNE USINE s\'engage à honorer les commandes reçues dans la limite des stocks disponibles et en cas d\'indisponibilité à en informer le CLIENT par tout moyen à sa convenance ; le CLIENT pourra alors annuler sa commande et se faire rembourser, le cas échéant, les sommes déjà versées.'}
      <br/><br/>
      {'UNE USINE se réserve le droit d\'annuler ou de refuser toute commande d\'un CLIENT avec lequel il existerait un litige relatif au paiement d\'une commande antérieure.'}
    </div>
    <h2>{'La livraison'}</h2>
    <div className="block-body">
      {'Les produits seront envoyés à l\'adresse de livraison indiquée au cours du processus de commande.'}
      <br/><br/>
      {'En cas d\'erreur dans le libellé des coordonnées du CLIENT, UNE USINE ne pourra être tenue responsable de l\'impossibilité de livrer le ou les articles commandés.'}
    </div>
    <h2>{'Date de livraison'}</h2>
    <div className="block-body">
      {'UNE USINE s\'engage à expédier la commande dans un délai de 7 jours suite à la réception du réglement. (en générale la commande est expédiée dans les 3 jours).'}
      <br/><br/>
      {'Si le délai devait excéder les 7 jours, UNE USINE s\'engage à avertir le CLIENT qui peut alors annuler la commande et en demander le remboursement.'}
    </div>
    <h2>{'Les prix'}</h2>
    <div className="block-body">
      {'Pour la France métropolitaine (Corse comprise), les prix indiqués sont exprimés en euros et s\'entendent toutes taxes comprises, frais de port inclus.'}
      <br/><br/>
      {'Pour les DOM ou l\'étranger, les prix indiqués sont exprimés en euros et s\'entendant toutes taxes comprises, hors frais de port qui sont à la charge du CLIENT. UNE USINE, à la réception de la commande, reviendra vers le CLIENT pour lui indiquer le montant des frais de port et il pourra alors confirmer ou annuler sa commande.'}
      <br/><br/>
      {'En cas d\'annulation, la réglement sera immédiatement remboursé.'}
      <br/><br/>
      {'Tout changement du taux légal de la T.V.A. applicable sera automatiquement répercuté sur les prix des articles, dès son entrée en vigueur.'}
      <br/><br/>
      {'L\'intégralité du paiement doit être réalisée lors de la commande.'}
      <br/><br/>
      {'A aucun moment les sommes versées ne pourront être considérées comme des arrhes ou des acomptes. Le CLIENT règlera sa commande chèque à l\'ordre de UNE USINE ou via PayPal.'}
      <br/><br/>
      {'UNE USINE conserve la propriété de l\'article jusqu\'au paiement intégral du prix par le CLIENT.'}
    </div>
    <h2>{'Propriété intellectuelle'}</h2>
    <div className="block-body">
      {'Tous les textes, commentaires, photos, marques, illustrations et images reproduits sur le site www.lespetitesemplettes.com sont protégés par la propriété intellectuelle et pour le monde entier.'}
      <br/><br/>
      {'Toute reproduction totale ou partielle du site www.lespetitesemplettes.com est strictement interdite.'}
    </div>
    <h2>{'Délai de rétractation'}</h2>
    <div className="block-body">
      {'Les caractéristiques essentielles des articles sont présentées sur www.lespetitesemplettes.com dans chacune des fiches articles. Le CLIENT est pleinement informé que les images, photos et les couleurs des articles mis en vente pourraient cependant ne pas correspondre aux couleurs réelles sous l\'effet du navigateur Internet et de l\'écran utilisé.'}
      <br/><br/>
      {'Le CLIENT, s\'il n\'est pas satisfait, dispose d\'un délai de rétractation de 14 jours à compter de la réception de l\'article. Pour se rétracter le CLIENT devra saisir un formulaire (disponible sur la page \'MON COMPTE\') et l\'envoyer à la société UNE USINE. Après réception du colis UNE USINE remboursera les sommes versées dans un délai de 10 jours.'}
      <br/><br/>
      {'L\'article devra être retourné à l\'état neuf, dans son emballage d\'origine avec la facture.'}
      <br/><br/>
      {'Les articles retournés incomplets, abîmés, endommagés, utilisés ou salis ne pourront pas être repris.'}
      <br/><br/>
      {'L\'article retourné sera remboursé au CLIENT dans un délai maximum de 10 jours à compter de sa réception par UNE USINE.'}
      <br/><br/>
      {'Le CLIENT pourra bénéficier de son droit de rétractation en cas de fourniture d\'article confectionné selon les spécifications du CLIENT ou nettement personnalisés.'}
      <br/><br/>
      {'Les articles sont retournés aux risques du CLIENT.'}
    </div>

    <h2>{'Défaut de l\'article'}</h2>
    <div className="block-body">
      {'UNE USINE est tenue des défauts de conformité du produit à la commande et des vices cachés dans les conditions prévues aux articles 1641 et 1649 du Code civil.'}
      <br/><br/>
      {'Conformément aux dispositions de l\'ordonnance n° 2005-136 du 17 février 2005, les articles du Code civil précités ainsi que les articles du Code de la consommation relatifs à la garantie des biens de consommation sont reproduits au sein des présentes Conditions Générales de Vente.'}
    </div>

    <h2>{'Données nominatives'}</h2>
    <div className="block-body">
      {'Les données nominatives collectées sur le site www.lespetitesemplettes.com, sont nécessaires au traitement de la commande et plus généralement à la gestion des relations commerciales de UNE USINE avec ses CLIENTS et sont destinées à UNE USINE et ses partenaires extérieurs.'}
      <br/><br/>
      {'Conformément à la loi du 6 janvier 1978, le CLIENT dispose d\'un droit d\'accès aux données nominatives le concernant qu\'il pourra exercer par écrit auprès du Service Relation Clientèle et ce, sans préjudice du droit dont il dispose par ailleurs de s\'opposer aux conditions précitées à l\'utilisation de ses données nominatives à des fins de sollicitations commerciales par UNE USINE seule ou en partenariat avec d\'autres sociétés et/ou directement par des tiers, sociétés cessionnaires desdites données nominatives.'}
    </div>

    <h2>{'Droit applicable et juridiction compétente'}</h2>
    <div className="blblock-bodyck">
      {'Les présentes conditions générales de vente sont soumises au droit français. En cas de litige compétence exclusive est attribuée aux tribunaux français compétents, nonobstant pluralité de défendeurs et/ou appel en garantie même pour les procédures d\'urgence ou les procédures conservatoires en référé ou par requête.'}
    </div>

    <h2>{'Reproduction des articles L211-4 L211-5 et L211-12 du code de la consommation'}</h2>
    <div className="block-body">
      <h3>{'Article L211-4'}</h3>
      {'Le vendeur est tenu de livrer un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance. Il répond également des défauts de conformité résultant de l\'emballage, des instructions de montage ou de l\'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité.'}
      <h3>{'Article L211-5'}</h3>
      {'Pour être conforme au contrat, le bien doit :'}
      <br/><br/>
      {'Etre propre à l\'usage habituellement attendu d\'un bien semblable et, le cas échéant :'}
      <ul>
        <li>
          {'correspondre à la description donnée par le vendeur et posséder les qualités que celui-ci a présentées à l\'acheteur sous forme d\'échantillon ou de modèle;'}
        </li>
        <li>
          {'présenter les qualités qu\'un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans la publicité ou l\'étiquetage ;'}
        </li>
        <li>
          {'ou présenter les caractéristiques définies d\'un commun accord par les parties ou être propre à tout usage spécial recherché par l\'acheteur, porté à la connaissance du vendeur et que ce dernier a accepté.'}
        </li>
      </ul>
      <h3>{'Article L211-12'}</h3>
      {'L\'action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du bien.'}
      <br/><br/>
      {'Reproduction des articles 1641 et 1648 AL 1 du code civil'}
      <h3>{'Article 1641'}</h3>
      {'Le vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui la rendent impropre à l\'usage auquel on la destine, ou qui diminuent tellement cet usage, que l\'acheteur ne l\'aurait pas acquise, ou n\'en aurait donné qu\'un moindre prix, s\'il les avait connus.'}
      <br/><br/>
      {'L\'action résultant des vices rédhibitoires doit être intentée par l\'acquéreur dans un délai de deux ans à compter de la découverte du vice'}
      <br/><br/>
      {'Les présentes Conditions Générales de Vente régissent les relations entre la Société UNE USINE, dont le siège social est Le Château 16410 Dirac, immatriculée au Registre du Commerce et des Sociétés d\'Angoulême sous le numéro 490 216 561 et les CLIENTS souhaitant effectuer un achat sur le site Internet www.lespetitesemplettes.com.'}
      <br/><br/><br/>
      {'Toute commande implique l\'acceptation pleine et entière des présentes Conditions Générales de Vente.'}
    </div>
  </div>
);

export default Cgv;
