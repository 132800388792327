
const R = require('ramda');
import React from 'react';
import marked from 'marked';

import { withIsMobile } from '../template/IsMobile';

const prepareRows = (items, rowSize, isMobile) => {
  let rows = [];
  let remainingItems = items;

  if (isMobile) {
    rowSize = Math.min(2, rowSize);
  }

  do {
    const itemsInRow = R.take(rowSize, remainingItems);
    rows = R.append(itemsInRow, rows);
    remainingItems = R.difference(remainingItems, itemsInRow);
  } while (R.length(remainingItems) > 0);

  return rows;
};

const Gallery = ({
  images,
  isMobile
}) => {
  return (
    <div>
      {
        (prepareRows(images, 4, isMobile).map(
          (row, index) => (
            <div 
              key={index}
              className="block-article-gallery-row"
            >
              {
                row.map(
                  ({ url }, index) => (
                    <div 
                      className="blog-article-block-image"
                      key={index} 
                    >
                      <img 
                        src={url}
                        style={{ width: '100%' }}
                      />
                    </div>
                  )
                )
              }
            </div>
          )))
      }
    </div>
  );
};

const BlogArticle = ({
  title,
  blocks,
  isMobile
}) => (
  <div>
    <h1>{title}</h1>
    {
      blocks.map(({ text, gallery, index }) => (
        <div key={index}>
          <div 
            dangerouslySetInnerHTML={{ __html: marked(text) }}
            className="blog-article-content"
          />
          <Gallery images={gallery} isMobile={isMobile}/>
        </div>
      ))
    }
  </div>
);

export default withIsMobile(BlogArticle);