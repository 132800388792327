import './Blog.less';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BlogPage from './BlogPage';
import BlogListPage from './BlogListPage';

const Blog = ({ match }) => (
  <div>
    <Switch>
      <Route path={`${match.url}`} exact component={BlogListPage}/>
      <Route
        path={`${match.url}/:articleId`} render={({ match }) => {
          return (<BlogPage articleId={match.params.articleId}/>);
        }}/>
    </Switch>
  </div>
);

export default Blog;