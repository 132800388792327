import React from 'react';
import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay';

import CartTotal from './CartTotal';

const CartTotalFragment = ({ total, isMobile }) => {
  return React.createElement(
    CartTotal,
    {
      total
    }
  );
};

CartTotalFragment.defaultProps = {
  isMobile: true
};

export default createFragmentContainer(
  CartTotalFragment,
  {
    total: graphql`
      fragment CartTotalFragment_total on CartTotal {
        id
        country
        productsWithTax
        productsNoTax
        shippingNoTax
        shippingWithTax
        extraShippingWithTax,
        extraShippingNoTax
        tax
        totalWithTaxWithoutCoupon
        totalWithTax
        totalNoTax
        mustCheckShippingCost
      }
    `
  }
);
