import React from 'react';
import Button from '../uikit/Button';
import { graphql, createFragmentContainer } from 'react-relay';

const CartOrder = ({ style, total }) => {
  return (
    <div className="cart-order" {...{ style }}>
      <Button to="/order" size="big" disabled={Number(total.totalWithTax) == 0}>
        Commander
      </Button>
    </div>
  );
};

export default createFragmentContainer(
  CartOrder,
  {
    total: graphql`
      fragment CartOrder_total on CartTotal {
        id
        totalWithTaxWithoutCoupon
        totalWithTax
      }
    `
  }
);